import { Boot, SlateEditor } from '@wangeditor/editor';

class RedMenu {
  constructor() {
    this.title = '标红';
    this.iconSvg = '<svg aria-hidden="true"><use xlink:href="#iconshuru-copy" /></svg>';
    this.tag = 'button';
  }
  getValue() {
    return false;
  }
  isActive(editor) {
    const marks = SlateEditor.marks(editor);
    if (marks && marks.color == '#F9431E') {
      return true;
    } else {
      return false;
    }
  }
  isDisabled() {
    return false; // or true
  }
  exec(editor) {
    editor.addMark('color', '#F9431E');
  }
}
class UnRedMenu {
  constructor() {
    this.title = '取消标红';
    this.iconSvg = '<svg aria-hidden="true"><use xlink:href="#iconshuru" /></svg>';
    this.tag = 'button';
  }
  getValue() {
    return false;
  }
  isActive(editor) {
    const marks = SlateEditor.marks(editor);
    if (marks && marks.color == '#F9431E') {
      return false;
    } else {
      return true;
    }
  }
  isDisabled() {
    return false; // or true
  }
  exec(editor) {
    editor.removeMark('color');
  }
}
const redMenuConf = {
  key: 'redMenu',
  factory() {
    return new RedMenu();
  },
};
const unRedMenuConf = {
  key: 'unRedMenu',
  factory() {
    return new UnRedMenu();
  },
};

export default class {
  constructor() {
    Boot.registerMenu(redMenuConf);
    Boot.registerMenu(unRedMenuConf);
  }
}
