import Http from '../api/http/';

export default class userPartTimeSalary extends Http {
  constructor() {
    super();
  }
  /***********兼职人员薪资管理***************/
  /**
   * 生成支付单据
   * @param {string} salaryIdList -  报酬ids
   * @param {string} name -  单据名称
   * @param {integer} userCount -  涉及用户数量
   * @param {number} sumMoney -  总金额
   * @param {string} cuid -  undefined
   */

  userPartTimeSalary_addReceipts() {
    return super.post('userPartTimeSalary/addReceipts', ...arguments);
  }

  /**
   * 添加奖惩明细
   * @param {Array} userPartTimeRewardPunishmentDTO -  userPartTimeRewardPunishmentDTO
   */

  userPartTimeSalary_addRewardPunish() {
    return super.post('userPartTimeSalary/addRewardPunish', ...arguments);
  }

  /**
   * 删除奖惩明细
   * @param {integer} id -  id
   */

  userPartTimeSalary_deleteRewardPunish() {
    return super.post('userPartTimeSalary/deleteRewardPunish', ...arguments);
  }

  /**
   * 生成支付单据列表
   * @param {string} salaryIdList -  薪资结算id
   */

  userPartTimeSalary_getReceipts() {
    return super.post('userPartTimeSalary/getReceipts', ...arguments);
  }

  /**
   * 薪资结算列表
   * @param {integer} taskSource -  单据任务来源 1p端 2b端
   * @param {string} companyName -  企业名称
   * @param {string} storeName -  门店名称
   * @param {string} storeId -  门店id
   * @param {string} storeAreaName -  门店区域
   * @param {integer} reportSendStatus -  下发状态0未发送 1已发送
   * @param {string} brandId -  品牌id
   * @param {string} brandName -  品牌名字
   * @param {integer} receiptsType -  单据生成状态（0-未生成，1-已生成）
   * @param {integer} payStatus -  支付状态 0-未支付，1-已支付，2-支付失败
   * @param {string} realName -  访客名称
   * @param {string} reportName -  报告名称
   * @param {integer} markDelete -  删除状态
   * @param {integer} permissionRange -  用户的数据范围 全部(不要传值) 1我负责的企业 2我负责的品牌
   * @param {integer} bankType -  银行类型 0国内银行 1国外银行
   * @param {string} userId -  undefined
   * @param {array} brandIds -  undefined
   * @param {ref} storeList -  数据权限门店id集合，后端赋值，前端不用传参
   * @param {ref} areaList -  数据权限区域id集合，后端赋值，前端不用传参
   * @param {string} companyId -  企业id
   * @param {string} operateUserId -  操作用户id
   * @param {integer} operateUserType -  用户类型(1-P端，2-B端)
   * @param {integer} downloadStatus -  下载状态(0-未下载，1-已下载)
   * @param {string} checkStartTime -  检查开始时间
   * @param {string} checkEndTime -  检查结束时间
   * @param {string} payStatusStr -  状态 0未生成、1未生成（已驳回）、2未支付、3支付失败、4线上支付、5线下支付
   * @param {string} payWayStr -  收款方式 0国外银行、1国内银行、2支付宝
   * @param {ref} payStatusList -  状态 0未生成、1未生成（已驳回）、2未支付、3支付失败、4线上支付、5线下支付
   * @param {ref} payWayList -  收款方式 0国外银行、1国内银行、2支付宝
   * @param {string} payTimeStart -  支付开始时间 yyyy-MM-dd
   * @param {string} payTimeEnd -  支付结束时间 yyyy-MM-dd
   * @param {integer} isExport -  undefined
   * @param {string} phone -  兼职手机号
   * @param {string} userName -  兼职姓名
   * @param {string} yearMonth -  年月
   * @param {integer} page -  页数
   * @param {integer} rows -  行数
   */

  userPartTimeSalary_getSalaryList() {
    return super.post('userPartTimeSalary/getSalaryList', ...arguments);
  }

  /**
   * 薪资明细
   * @param {integer} id -  id
   */

  userPartTimeSalary_salaryDetail() {
    return super.post('userPartTimeSalary/salaryDetail', ...arguments);
  }

  /**
   * 薪资审核明细
   * @param {integer} id -  id
   * @param {integer} page -  page
   * @param {integer} rows -  rows
   */

  userPartTimeSalary_salaryDetailAudit() {
    return super.post('userPartTimeSalary/salaryDetailAudit', ...arguments);
  }

  /**
   * 薪资奖惩明细
   * @param {integer} id -  id
   * @param {integer} page -  page
   * @param {integer} rows -  rows
   */

  userPartTimeSalary_salaryDetailRewardPunishment() {
    return super.post('userPartTimeSalary/salaryDetailRewardPunishment', ...arguments);
  }

  /**
   * 修改奖惩明细
   * @param {Array} userPartTimeRewardPunishmentDTO -  userPartTimeRewardPunishmentDTO
   */

  userPartTimeSalary_updateRewardPunish() {
    return super.post('userPartTimeSalary/updateRewardPunish', ...arguments);
  }

  /**
   * 线下支付
   * @param {integer} id -  id
   * @param {string} reason -  reason
   */

  userPartTimeSalary_offlinePay() {
    return super.post('userPartTimeSalary/offlinePay', ...arguments);
  }

  /***
   * 薪资结算导出
   */
  userPartTimeSalary_export() {
    return super.post('userPartTimeSalary/export', ...arguments);
  }

  /**
   * 薪资结算明细导出
   * @returns
   */
  userPartTimeSalary_exportDetail() {
    return super.get('userPartTimeSalary/exportDetail', ...arguments);
  }
}
