export default {
  SET_USER_INFO: 'SET_USER_INFO',
  SET_PARAMS: 'SET_PARAMS',
  SET_ANALYSIS_VALID: 'SET_ANALYSIS_VALID',
  SET_LEFT_ACTION: 'SET_LEFT_ACTION',
  SET_HEAD_ACTION: 'SET_HEAD_ACTION',
  SET_NEWS_COUNT: 'SET_NEWS_COUNT',
  SET_TOTAL_ANALYSIS_NAVMENU: 'SET_TOTAL_ANALYSIS_NAVMENU',
  SET_TOTAL_ANALYSIS_NAVMENU_ACTIVEINDEX: 'SET_TOTAL_ANALYSIS_NAVMENU_ACTIVEINDEX',
  Set_Current_Version: 'Set_Current_Version',
  Set_Behavior_Body_Add: 'Set_Behavior_Body_Add',
  Set_Behavior_Body_Del: 'Set_Behavior_Body_Del',
  Set_Permissions: 'Set_Permissions',
  Set_RemindBanner: 'Set_RemindBanner',
};
