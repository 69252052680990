import Http from '../api/http/';
export default class QuestionFeed extends Http {
  constructor() {
    super();
  }

  /**
   * 问题反馈批处理
   * @param {Array} questionFeedbackDealwithDto -  questionFeedbackDealwithDto
   */
  questionFeed_batchDealwith() {
    return super.post('questionFeed/batchDealwith', ...arguments);
  }

  /**
   * 导出问题反馈
   * @param {string} userName -  姓名
   * @param {integer} type -  反馈类型 0定位不准反馈 1帮助反馈 2举报/检举
   * @param {integer} listType -  列表类型 0定位不准反馈 1其他
   * @param {integer} results -  处理结果 0处理中 1已处理
   * @param {integer} calibrationStatus -  处理结果 0处理中 1已处理
   * @param {string} feedbackTimeStart -  反馈时间起始时间
   * @param {string} feedbackTimeEnd -  反馈时间结束时间
   */

  questionFeed_exportQuestionFeed() {
    return super.get('questionFeed/exportQuestionFeed', ...arguments);
  }

  /***********问题反馈管理***************/
  /**
   * 问题反馈处理
   * @param {string} id -  问题反馈id
   */
  questionFeed_dealwith() {
    return super.post('questionFeed/dealwith', ...arguments);
  }

  /**
   * 问题反馈详细
   * @param {string} id -  问题反馈
   */
  questionFeed_detail() {
    return super.post('questionFeed/detail', ...arguments);
  }

  /**
   * 问题反馈列表
   * @param {string} userName -  姓名
   * @param {integer} type -  反馈类型 0定位不准反馈 1帮助反馈
   * @param {integer} results -  处理结果 0处理中 1已处理
   * @param {string} feedbackTimeStart -  反馈时间起始时间
   * @param {string} feedbackTimeEnd -  反馈时间结束时间
   * @param {integer} page -  page
   * @param {integer} rows -  rows
   */
  questionFeed_list() {
    return super.post('questionFeed/list', ...arguments);
  }

  /**
   * 意见反馈转发
   * @param {string} id -  问题反馈id
   * @param {string} preHandlerId -  预处理人id
   */

  questionFeed_questionFeedbackTransfer() {
    return super.post('questionFeed/questionFeedbackTransfer', ...arguments);
  }

  /**
   * 意见反馈转发
   * @param {string} id -  问题反馈id
   * @param {string} preHandlerId -  预处理人id
   */

  questionFeed_questionFeedbackTransferNoPermission() {
    return super.post('questionFeed/questionFeedbackTransferNoPermission', ...arguments);
  }

  /**
   * 问题反馈处理
   * @param {string} id -  问题反馈id
   * @param {integer} questionType -  问题分类
   * @param {string} suggestion -  处理意见
   */

  questionFeed_dealwithNoPermission() {
    return super.post('questionFeed/dealwithNoPermission', ...arguments);
  }

  /**
   * 问题反馈详细
   * @param {string} id -  问题反馈
   */

  questionFeed_detailNoPermission() {
    return super.post('questionFeed/detailNoPermission', ...arguments);
  }

  /**
   * 问题反馈列表
   * @param {string} userName -  姓名
   * @param {integer} type -  反馈类型 0定位不准反馈 1帮助反馈 2举报/检举
   * @param {integer} questionType -  问题分类：1系统bug/优化 2任务执行 3客诉问题 4其它问题
   * @param {integer} listType -  列表类型 0定位不准反馈 1其他
   * @param {integer} results -  处理结果 0待处理 1已处理 2处理中
   * @param {integer} calibrationStatus -  处理结果 0处理中 1已处理
   * @param {string} feedbackTimeStart -  反馈时间起始时间
   * @param {string} feedbackTimeEnd -  反馈时间结束时间
   * @param {string} handlerId -  处理人
   * @param {integer} page -  page
   * @param {integer} rows -  rows
   */

  questionFeed_getQuestionFeedListNoPermission() {
    return super.post('questionFeed/getQuestionFeedListNoPermission', ...arguments);
  }

  /**
   * 新增问题反馈
   * @param {string} content -  反馈内容
   * @param {string} feedbackTime -  反馈时间
   * @param {Array} files -  上传文件
   * @param {string} phone -  手机号
   * @param {string} source -  反馈来源
   * @param {string} userName -  反馈来源
   * @param {integer} type -  反馈类型
   */

  questionFeed_addInfoByP() {
    return super.post('questionFeed/addInfoByP', ...arguments);
  }
  /**
   * 获取反馈类型
   */

  questionFeed_getInfosForTypes() {
    return super.post('questionFeed/getInfosForTypes', ...arguments);
  }

  /**
   * 运营总结填写
   */

  questionFeed_operationSummary() {
    return super.post('questionFeed/operationSummary', ...arguments);
  }

  /**
   * 获取问题分类- 反馈类型的对应关系
   */

  questionFeed_getInfosForQuestionTypes() {
    return super.post('questionFeed/getInfosForQuestionTypes', ...arguments);
  }

  /**
   * @param {string} id
   * 获取流转记录
   */

  questionFeed_getRecords() {
    return super.post('questionFeed/getRecords', ...arguments);
  }

  /**
   * 
   * 获取问题分类，不带对应关系的类型
   */

  questionFeed_getInfosForQuestionTypeNoRef() {
    return super.post('questionFeed/getInfosForQuestionTypeNoRef', ...arguments);
  }

}
