import Http from '../api/http/';
export default class Bill extends Http {
  constructor() {
    super();
  }
  // 添加企业账单
  bill_add() {
    return super.post('bill/add', ...arguments);
  }
  // 企业账单详细
  bill_deatil() {
    return super.post('bill/deatil', ...arguments);
  }
  /**
   * 企业账单列表
   *
   *  @param { companyBillListDTO }
   *
   **/
  bill_estimateList() {
    return super.post('bill/estimateList', ...arguments, {});
  }
  // 发送账单
  bill_send() {
    return super.post('bill/send', ...arguments);
  }
  // 修改企业账单
  bill_update() {
    return super.post('bill/update', ...arguments);
  }
  // 撤回账单
  bill_withdraw() {
    return super.post('bill/withdraw', ...arguments);
  }
  //发票详情
  bill_invoiceDeatil() {
    return super.post('bill/invoiceDeatil', ...arguments);
  }
  // 删除企业账单
  bill_delete() {
    return super.post('bill/delete', ...arguments);
  }
  // 下载企业账单
  bill_download() {
    return super.get('bill/download', ...arguments);
  }
  // 付款信息
  bill_payInfo() {
    return super.post('bill/payInfo', ...arguments);
  }
  // 确认收款
  bill_sureReceiveMoney() {
    return super.post('bill/sureReceiveMoney', ...arguments);
  }
  //添加收款记录
  bill_addBillReceivable() {
    return super.post('bill/addBillReceivable', ...arguments, {});
  }
  //收款明细
  bill_billReceivableDetail() {
    return super.post('bill/billReceivableDetail', ...arguments);
  }
  //删除账单记录
  bill_deleteBillReceivable() {
    return super.post('bill/deleteBillReceivable', ...arguments);
  }
  //修改收款记录
  bill_updateBillReceivable() {
    return super.post('bill/updateBillReceivable', ...arguments, {});
  }
  // 取消确认收款记录
  bill_cancelConfirmBillReceivable() {
    return super.post('bill/cancelConfirmBillReceivable', ...arguments);
  }
  // 确认收款记录
  bill_confirmBillReceivable() {
    return super.post('bill/confirmBillReceivable', ...arguments);
  }
  // 导出收款
  bill_exportBillReceivable() {
    return super.get('bill/exportBillReceivable', ...arguments);
  }

  /**
   * 根据任务id获取账单明细列表
   * @param {Array} taskDimensionBillDetailDTO -  taskDimensionBillDetailDTO
   */

  bill_getTaskDimensionBillDetail() {
    return super.post('bill/getTaskDimensionBillDetail', ...arguments);
  }
  /**
   * 根据任务id获取账单明细列表
   * @param {Array} idListDto -  idListDto
   */

  bill_getBillDetailsByTaskIds() {
    return super.post('bill/getBillDetailsByTaskIds', ...arguments);
  }
  /**
   * 签约
   * @param {integer} billId -  billId
   */

  bill_sign() {
    return super.post('bill/sign', ...arguments);
  }
  /**
   * 获取账单下载地址
   * */

  bill_getBillSignDownloadUrl() {
    return super.post('bill/getBillSignDownloadUrl', ...arguments);
  }

  /**
   * 获取账单查看地址
   * */
  bill_getBillSignViewUrl() {
    return super.post('bill/getBillSignViewUrl', ...arguments);
  }
  /**
   * 根据报告id获取账单门店统计明细列表
   * @param {Array} taskStoreDetailIdsDto -  taskStoreDetailIdsDto
   */
  bill_getBillStoreStatisticsByTaskStoreDetailIds() {
    return super.post('bill/getBillStoreStatisticsByTaskStoreDetailIds', ...arguments);
  }
  /**
   * 获取账单费用类型
   */

  bill_getBillTypeOptionList() {
    return super.post('bill/getBillTypeOptionList', ...arguments);
  }
  /**
   * 预估企业账单关联列表
   * @param {Array} companyBillListDTO -  companyBillListDTO
   */

  bill_estimateBillRelationList() {
    return super.post('bill/estimateBillRelationList', ...arguments, {});
  }
  /**
   * 取消关联预估账单
   * @param {integer} actualBillId -  实销账单id
   * @param {integer} estimateBillId -  预估账单id
   */

  bill_cancelRelationEstimateBill() {
    return super.post('bill/cancelRelationEstimateBill', ...arguments);
  }
  /**
   * 关联预估账单
   * @param {integer} actualBillId -  实销账单id
   * @param {integer} estimateBillId -  预估账单id
   */

  bill_relationEstimateBill() {
    return super.post('bill/relationEstimateBill', ...arguments);
  }
  /**
   * 获取账单费用类型(包含不显示的)
   */

  bill_getBillTypeOptionListIncudeNotShow() {
    return super.post('bill/getBillTypeOptionListIncudeNotShow', ...arguments);
  }
  /**
   * 获取账单分享地址
   * @param {Array} id -  账单id
   */

  bill_getBillSignShareUrl() {
    return super.post('bill/getBillSignShareUrl', ...arguments);
  }
  /**
   * 确认账单
   * @param {integer} id -  账单id
   */

  bill_confirmBill() {
    return super.post('bill/confirmBill', ...arguments);
  }
  /**
   * 发票添加
   * @param {string} id -  发票账单关联id
   * @param {integer} billId -  账单id
   * @param {string} invoiceId -  发票id序号
   * @param {string} amount -  开票金额
   * @param {string} remark -  备注
   */

  bill_addInvoice() {
    return super.post('bill/addInvoice', ...arguments);
  }
  /**
   * 发票删除
   * @param {string} id -  id
   */

  bill_deleteInvoice() {
    return super.post('bill/deleteInvoice', ...arguments);
  }
  /**
   * 发票修改
   * @param {string} id -  发票账单关联id
   * @param {integer} billId -  账单id
   * @param {string} invoiceId -  发票id序号
   * @param {string} amount -  开票金额
   * @param {string} remark -  备注
   */

  bill_updateInvoice() {
    return super.post('bill/updateInvoice', ...arguments);
  }
  /**
   * 发票申请
   * @param {integer} billId -  账单id
   */

  bill_applyInvoice() {
    return super.post('bill/applyInvoice', ...arguments);
  }
  /**
   * 批量导入发票申请
   * @param {string} excelFile -  excelFile
   * @param {integer} billId -  账单id
   */

  bill_importInvoiceApply() {
    return super.post('bill/importInvoiceApply', ...arguments);
  }
  /**
   * 引用账单发票
   * @param {Array} referenceInvoiceApplyDto -  referenceInvoiceApplyDto
   */

  bill_referenceInvoiceApplyByBillIds() {
    return super.post('bill/referenceInvoiceApplyByBillIds', ...arguments, {});
  }
  /**
   * 获取企业账单版本号
   * @param {string} companyId -  企业id
   */
  bill_getCompanyBillVersion() {
    return super.post('bill/getCompanyBillVersion', ...arguments);
  }
  /**
   * 获取产品服务列表
   *
   */
  bill_productCategoryList() {
    return super.post('bill/productCategoryList', ...arguments);
  }
  /**
   * 获取产品服务列表
   *  @param {string} companyId -  企业id
   *
   */
  bill_getCompanyBillDefaultInfo() {
    return super.post('bill/getCompanyBillDefaultInfo', ...arguments);
  }
  /**
   * 获取产品业务线列表
   *  @param {string} companyId -  企业id
   *
   */
  bill_getProductBusinessLine() {
    return super.post('bill/getProductBusinessLine', ...arguments);
  }
  // 收入列表
  bill_actualBillList() {
    return super.post('bill/actualBillList', ...arguments);
  }
  // 导出收入列表
  bill_exportActualBill() {
    return super.post('bill/exportActualBill', ...arguments);
  }
  // 收入列表-明细详情
  bill_actualDetail() {
    return super.post('bill/actualDetail', ...arguments);
  }
  // 获取未核销的收款列表
  bill_getHaveResidueRelateRechargeList() {
    return super.post('bill/getHaveResidueRelateRechargeList', ...arguments);
  }
  // 获取已核销的收款列表
  bill_getEstimateBillRelationRechargeList() {
    return super.post('bill/getEstimateBillRelationRechargeList', ...arguments);
  }
  // 取消核销收款
  bill_cancelRelationEstimateRecharge() {
    return super.post('bill/cancelRelationEstimateRecharge', ...arguments);
  }
  // 核销收款
  bill_relationEstimateRecharge() {
    return super.post('bill/relationEstimateRecharge', ...arguments);
  }
  // 推送收款列表到CRM
  bill_pushEstimateBillRelationRechargeToCrm() {
    return super.post('bill/pushEstimateBillRelationRechargeToCrm', ...arguments);
  }
  // 根据账单id查询，判断预估账单是否有核销收入记录
  bill_isEstimateBillHaveRelationActualList() {
    return super.post('bill/isEstimateBillHaveRelationActualList', ...arguments);
  }
  // 核销实销账单校验
  bill_relationEstimateActualCheck() {
    return super.post('bill/relationEstimateActualCheck', ...arguments);
  }
  // 核销实销账单
  bill_relationEstimateActual() {
    return super.post('bill/relationEstimateActual', ...arguments);
  }
  // 根据账单id查询 获取已经核销的实销账单列表
  bill_getEstimateBillRelationActualList() {
    return super.post('bill/getEstimateBillRelationActualList', ...arguments);
  }
  // 根据账单id查询 获取未核销的实效账单列表
  bill_getHaveResidueRelateActualList() {
    return super.post('bill/getHaveResidueRelateActualList', ...arguments);
  }
  // 取消核销实销账单
  bill_cancelRelationEstimateActual() {
    return super.post('bill/cancelRelationEstimateActual', ...arguments);
  }
  // 获取账单的核销金额
  bill_getCompanyResidueRelateMoney() {
    return super.post('bill/getCompanyResidueRelateMoney', ...arguments);
  }
  // 获取账单的核销金额
  bill_companyConfirmCheck() {
    return super.post('bill/companyConfirmCheck', ...arguments);
  }
  // 新建实销账单
  bill_addActual() {
    return super.post('bill/addActual', ...arguments);
  }
  // 推送收款列表到CRM校验
  bill_pushRechargeToCrmCheck() {
    return super.post('bill/pushRechargeToCrmCheck', ...arguments);
  }
  // 推送实销账单到CRM校验
  bill_pushActualToCrmCheck() {
    return super.post('bill/pushActualToCrmCheck', ...arguments);
  }
  // 根据报告id获取账单报告明细列表
  bill_getBillReportByTaskStoreDetailIds() {
    return super.post('bill/getBillReportByTaskStoreDetailIds', ...arguments);
  }
  // 导出账单
  bill_exportEstimateBill() {
    return super.post('bill/exportEstimateBill', ...arguments);
  }
  /**
   * 批量推送
   *@param {Array} actualBillIds -  实销账单ids
   */
  bill_pushEstimateBillRelationActualToCrm() {
    return super.post('bill/pushEstimateBillRelationActualToCrm', ...arguments);
  }
  /**
   * 根据收入id查询 获取已经核销的预估账单列表
   * @param {number} actualBillId -  实销账单id
   */
  bill_getActualBillRelationEstimateList() {
    return super.post('bill/getActualBillRelationEstimateList', ...arguments);
  }
}
