import Http from '../api/http/';

export default class Task extends Http {
  constructor() {
    super();
  }

  /***********任务管理***************/

  /**
   * @description 获取用户下的品牌列表
   * @param
   */
  task_getBrandListByUser() {
    return super.post('task/getBrandListByUser', ...arguments);
  }
  task_getStoreListByBid() {
    return super.post('task/getStoreListByBid', ...arguments);
  }
  task_getStoreByName() {
    return super.post('task/getStoreByName', ...arguments);
  }
  task_getTaskList() {
    return super.post('task/getTaskList', ...arguments);
  }
  task_createTaskNew() {
    return super.post('task/createTaskNew', ...arguments);
  }
  //获取线下报告模板
  task_getOfflineEnterpriseTemplateListByBrandId() {
    return super.post('task/getOfflineEnterpriseTemplateListByBrandId', ...arguments);
  }
  //上传或修改线下模板
  task_addOrAlterTaskTemplate() {
    return super.post('task/addOrAlterTaskTemplate', ...arguments);
  }
  //删除线下模板
  task_removeTaskTemplate() {
    return super.post('task/removeTaskTemplate', ...arguments);
  }
  //引用任务 获取数据
  task_getTaskInfoByTaskId() {
    return super.post('task/getTaskInfoByTaskId', ...arguments);
  }
  //发布任务
  task_taskPublish() {
    return super.post('task/taskPublish', ...arguments);
  }
  //任务进度列表
  task_taskProgressList() {
    return super.post('task/taskProgressList', ...arguments, {});
  }
  //设置门店
  task_setTaskUser() {
    return super.post('task/setTaskUser', ...arguments, {});
  }
  //结束任务
  task_taskOver() {
    return super.post('task/taskOver', ...arguments);
  }
  //删除任务
  task_taskRemove() {
    return super.post('task/taskRemove', ...arguments);
  }
  //修改任务
  task_updateTaskNew() {
    return super.post('task/updateTaskNew', ...arguments);
  }

  //删除门店
  task_deleteTaskStore() {
    return super.post('task/deleteTaskStore', ...arguments);
  }
  // 批量删除门店
  task_batchDeleteTaskStore() {
    return super.post('task/batchDeleteTaskStore', ...arguments);
  }
  //撤回
  task_taskRecall() {
    return super.post('task/taskRecall', ...arguments);
  }
  //失败统计
  task_taskFailureList() {
    return super.post('task/taskFailureList', ...arguments);
  }
  //通知
  task_taskMessage() {
    return super.post('task/taskMessage', ...arguments);
  }

  /**
   * 消息通知列表
   * @param {string} taskId -  任务Id
   * @param {integer} page -  page
   * @param {integer} rows -  rows
   */

  task_getTaskMessageList() {
    return super.post('task/getTaskMessageList', ...arguments);
  }

  /**
   * 消息通知详情列表
   * @param {string} taskId -  任务Id
   * @param {string} userName -  嘉宾姓名
   * @param {integer} readStatus -  查看状态 0 未读  1已读
   * @param {integer} page -  page
   * @param {integer} rows -  rows
   */

  task_getTaskMessageDetailList() {
    return super.post('task/getTaskMessageDetailList', ...arguments);
  }

  /**
   * 任务详情预览
   * @param {string} taskId -  任务id
   * @param {string} detail -  任务详情
   */

  task_preview() {
    return super.post('task/preview', ...arguments);
  }

  /**
   * 任务消息重新发送 单条发 批量发
   * @param {string} messageIds -  消息Id 多个以英文分号‘;’隔开
   */

  task_taskMessageRepeatSend() {
    return super.post('task/taskMessageRepeatSend', ...arguments);
  }

  //关注详情
  task_attentionUserInfo() {
    return super.post('task/attentionUserInfo', ...arguments);
  }
  //查看进度释放任务
  task_minusGcount() {
    return super.post('task/minusGcount', ...arguments);
  }

  //查看任务报名情况
  task_getTaskApplyInfo() {
    return super.post('task/getTaskApplyInfo', ...arguments, {});
  }
  //获取任务门店预到店时间
  task_getTaskStoreDiningDate() {
    return super.post('task/getTaskStoreDiningDate', ...arguments);
  }
  //获取困难任务列表
  task_getDifficultTaskList() {
    return super.post('task/getDifficultTaskList', ...arguments, {});
  }
  /**
   * 困难任务关注用户的信息
   * @param {string} taskStoreId -  taskStoreId
   * @param {integer} page -  page
   * @param {integer} rows -  rows
   */

  task_getAttentionUserByTaskStoreId() {
    return super.post('task/getAttentionUserByTaskStoreId', ...arguments);
  }
  /**
   * 匹配人员消息通知
   * @param {Array} req -  req
   */
  task_taskMessageToUsers() {
    return super.post('task/taskMessageToUsers', ...arguments, {});
  }

  /**
   * 校验访客
   * @param {string} userId -  用户Id
   * @param {string} taskStoreId -  任务门店Id
   */
  task_validateTaskUser() {
    return super.post('task/validateTaskUser', ...arguments);
  }

  /**
   * 推荐任务查看进度-指定任务列表
   * @param {string} taskName -  任务名称
   * @param {integer} cityId -  城市id
   * @param {string} storeName -  门店名称
   * @param {string} brandName -  品牌名称
   * @param {integer} taskType -  任务模式 1抽签任务 2私探任务
   * @param {integer} taskSource -  任务来源 1平台端 2企业端
   * @param {integer} page -  当前页
   * @param {integer} rows -  每页显示数量
   */

  task_recommendTaskPageList() {
    return super.post('task/recommendTaskPageList', ...arguments);
  }
  //获取全部任务的全部创建人
  task_getTaskCreateUsers() {
    return super.post('task/getTaskCreateUsers');
  }
  //获取到所有的品牌（无数据权限）
  task_getBrandListAll() {
    return super.post('task/getBrandListAll');
  }
  /**
   * 导入任务门店
   * @param {string} brandId -  品牌id
   * @param {file} excelFile -  上传文件
   */

  task_taskStoreImport() {
    return super.post('task/taskStoreImport', ...arguments);
  }
  /**
   * 付款信息
   * @param {string} brandId -  brandId
   */

  task_payInfo() {
    return super.post('task/payInfo', ...arguments);
  }

  /**
   * 获取中签算法列表
   * @param {string} brandId -  品牌id
   * @param {string} taskId -  任务id
   */
  task_getTaskDrawConfigVoList() {
    return super.post('task/getTaskDrawConfigVoList', ...arguments);
  }
  /**
   * 停止或者启动抽签
   * @param {string} taskStoreId -  门店任务id
   * @param {integer} isStopDraw -  是否停止抽签1停止0启动
   */

  task_manualStopOrStartDraw() {
    return super.post('task/manualStopOrStartDraw', ...arguments);
  }
  /**
   * 企业任务列表
   * @param {Array} taskListBDto -  taskListBDto
   */

  task_getCompanyTaskList() {
    return super.post('task/getCompanyTaskList', ...arguments, {});
  }
  /**
   * 企业任务详情
   * @param {string} taskId -  任务id
   * @param {integer} whetherQuote -  是否是引用 0不是 1是 可不传，不传是不引用
   */

  task_companyDetail() {
    return super.post('task/companyDetail', ...arguments);
  }
  /**
   * 获取设置的访客信息
   * @param {string} taskStoreId -  任务门店Id
   * @param {integer} setTaskUserType -  指定类型1预指定2重新指定3指定
   */
  task_getSetTaskUserInfo() {
    return super.post('task/getSetTaskUserInfo', ...arguments);
  }
  /**
   * 任务进度
   * @param {Array} taskProgressDto -  taskProgressDto
   */
  task_getTaskStoreProgressList() {
    return super.post('task/getTaskStoreProgressList', ...arguments, {});
  }
  /**
   * 任务进度导出
   * @param {Array} taskProgressDto -  taskProgressDto
   */
  task_exportTaskStoreProgressList() {
    return super.post('task/exportTaskStoreProgressList', ...arguments, {});
  }
  /**
   * 取消预设访客
   * @param {string} taskStoreId -  任务门店Id
   */
  task_cancelPreSetTaskUser() {
    return super.post('task/cancelPreSetTaskUser', ...arguments);
  }
  /**
   * 导出任务进度详情
   * @param {Array} taskProgressDto -  taskProgressDto
   */
  task_exportTaskProgress() {
    return super.post('task/exportTaskProgress', ...arguments, {});
  }
  /**
   * 导出用户信息
   * @param {Array} user2CListDTO -  user2CListDTO
   */
  user_exportUserInfo() {
    return super.post('user/exportUserInfo', ...arguments, {});
  }
  /**
   * 按照type获取日期 1:节假日 2:工作日
   * @param {string} type -  类型
   * @param {string} startDate -  开始时间
   * @param {string} endDate -  结束时间
   */
  task_getDayByType() {
    return super.post('task/getDayByType', ...arguments);
  }
  /**
   * 地区批量导入
   * @param {string} brandId -  品牌id
   * @param {integer} taskType -  任务类型 3:打卡任务 4:线上调研
   * @param {string} startTime -  任务开始时间
   * @param {string} endTime -  任务结束时间
   * @param {file} excelFile -  上传文件
   */
  task_taskStoreImportForCity() {
    return super.post('task/taskStoreImportForCity', ...arguments);
  }
  /**
   * 获取符合客群用户人数
   * @param {Array} req -  req
   */
  task_getCountByTaskPublish() {
    return super.post('task/getCountByTaskPublish', ...arguments);
  }
  /**
   * 修改酬劳
   * @param {Array} updateTaskStoreDetailMoneyDto -  updateTaskStoreDetailMoneyDto
   */
  task_updateTaskStoreDetailMoney() {
    return super.post('task/updateTaskStoreDetailMoney', ...arguments);
  }
  /**
   * 获取酬劳信息
   * @param {Array} moneyDto -  moneyDto
   */
  task_getTaskStoreDetailMoney() {
    return super.post('task/getTaskStoreDetailMoney', ...arguments);
  }
  /**
   * 判断企业，品牌是否有进行中的任务
   * @param {string} companyId -  企业id
   * @param {string} brandId -  品牌id
   * @param {string} storeId -  门店id
   */
  task_judgeTask() {
    return super.post('task/judgeTask', ...arguments);
  }
}
