import Http from '../api/http/';
export default class platformUser extends Http {
  constructor() {
    super();
  }

  //获取平台用户数据
  platUser_getUser2PPage() {
    return super.post('platUser/getUser2PPage', ...arguments);
  }

  //创建平台用户
  platUser_create2P() {
    return super.post('platUser/create2P', ...arguments);
  }

  /**
   * 获取平台部门列表
   */
  platUser_getPlatDepartmentList() {
    return super.post('platUser/getPlatDepartmentList', ...arguments);
  }

  // 修改平台用户
  platUser_update2PUserNameAndRole() {
    return super.post('platUser/update2PUserNameAndRole', ...arguments);
  }

  // 登出
  platUser_logout() {
    return super.post('platUser/logout', ...arguments);
  }
  /**
   * 获取成本中心列表
   *
   */
  platUser_getCostCenterList() {
    return super.post('platUser/getCostCenterList', ...arguments);
  }
}
